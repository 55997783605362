<template>
  <div class="error-404">
    <div class="text">
      <h2>Error</h2>
      <div class="box">
        <h1>404</h1>
        <h4>Page not found</h4>
      </div>
      <button @click="goHome">Back to Home</button>
    </div>
    <div class="image">
      <img src="@/assets/spidey.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Error404",
  methods: {
    goHome: function() {
      this.$router.push({ path: "/" });
    }
  }
};
</script>

<style lang="scss" scoped>
.error-404 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: var(--dark00);
  .text {
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      margin: 0 0 10px 0;
      text-decoration: underline 8px solid var(--color07);
    }
    .box {
      border: 7px solid var(--color00);
      margin: 10px 0 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h1,
    h4 {
      margin: 0;
    }
    button {
      background-color: var(--dark00);
      color: var(--light00);
      border-radius: 20px;
      box-shadow: none;
      outline: none;
      border: none;
      padding: 7px;
      font-family: "Montserrat", sans-serif;
      cursor: pointer;
      text-decoration: none;
    }
    button:hover {
      background-color: var(--color07);
      color: white;
    }
  }
  .image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      background: var(--light01);
      width: 100%;
      height: auto;
    }
    button {
      z-index: 1;
    }
  }
}
</style>
